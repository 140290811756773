import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  Grid,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InputMask from "react-input-mask";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Importando useNavigate

const estadosBrasileiros = [
  { sigla: "AC", nome: "Acre" },
  { sigla: "AL", nome: "Alagoas" },
  { sigla: "AP", nome: "Amapá" },
  { sigla: "AM", nome: "Amazonas" },
  { sigla: "BA", nome: "Bahia" },
  { sigla: "CE", nome: "Ceará" },
  { sigla: "DF", nome: "Distrito Federal" },
  { sigla: "ES", nome: "Espírito Santo" },
  { sigla: "GO", nome: "Goiás" },
  { sigla: "MA", nome: "Maranhão" },
  { sigla: "MT", nome: "Mato Grosso" },
  { sigla: "MS", nome: "Mato Grosso do Sul" },
  { sigla: "MG", nome: "Minas Gerais" },
  { sigla: "PA", nome: "Pará" },
  { sigla: "PB", nome: "Paraíba" },
  { sigla: "PR", nome: "Paraná" },
  { sigla: "PE", nome: "Pernambuco" },
  { sigla: "PI", nome: "Piauí" },
  { sigla: "RJ", nome: "Rio de Janeiro" },
  { sigla: "RN", nome: "Rio Grande do Norte" },
  { sigla: "RS", nome: "Rio Grande do Sul" },
  { sigla: "RO", nome: "Rondônia" },
  { sigla: "RR", nome: "Roraima" },
  { sigla: "SC", nome: "Santa Catarina" },
  { sigla: "SP", nome: "São Paulo" },
  { sigla: "SE", nome: "Sergipe" },
  { sigla: "TO", nome: "Tocantins" },
];

const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  return resto === parseInt(cpf.substring(10, 11));
};

const CadastroForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  // Observe o campo de CEP
  const cep = watch("cep");
  const navigate = useNavigate(); // Inicializando o useNavigate
  const [checkDados, setCheckDados] = React.useState(false);
  const [checkDeclaracao, setCheckDeclaracao] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false); // Estado para controlar o loading

  function limparStringCompleta(str) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  const gerarNumeroAleatorio = () => {
    // Gera um número aleatório entre 0 e 9999999999 (10 dígitos)
    return Math.floor(1000000000 + Math.random() * 9000000000);
  };

  // Exemplo de uso
  const numeroAleatorio = gerarNumeroAleatorio();

  const onSubmit = async (data) => {
    setLoading(true); // Inicia o loading

    // Payload para a chamada ao endpoint
    const payload = {
      acquirer: "syncpay",
      costumerData: {
        name: data.nomeCompleto,
        document: limparStringCompleta(data.cpf),
        email: data.email,
        phone: limparStringCompleta(data.telefone),
        address: {
          street: data.endereco,
          number: data.numero,
          district: data.bairro,
          city: data.cidade,
          state: data.uf,
          zipcode: limparStringCompleta(data.cep),
          country: "BRA",
        },
        ip: "192.168.1.1",
      },
      invoiceData: {
        client: {
          document: limparStringCompleta(data.cpf),
          email: data.email,
          ip: "192.168.1.1",
        },
        payment: {
          type: "3",
          due_at: "2025-12-02",
          installment: "1",
          store_url: "https://google.com",
          order_url: "https://example.com/order/123",
          products: [
            {
              id: "1",
              title: "Taxa_PASSMK",
              qnt: "1",
              amount: "257.25", // Envia o valor formatado
            },
          ],
        },
      },
    };

    try {
      const response = await axios.post(
        "https://passport-api-urnz.onrender.com/create-invoice",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Fatura criada com sucesso:", response.data);

      navigate("/pagamento", {
        state: { invoiceData: response.data, numeroAleatorio, user: data },
      });
    } catch (error) {
      console.error("Erro ao criar fatura:", error);
      setErrorMessage("Erro ao criar a fatura. Tente novamente.");
    } finally {
      setLoading(false); // Finaliza o loading
    }
  };

  // Função para buscar dados do CEP
  const buscarEnderecoPorCEP = async (cep) => {
    const cepFormatado = cep.replace(/\D/g, "");
    if (cepFormatado.length !== 8) {
      console.error("CEP deve ter 8 dígitos");
      return;
    }
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${cepFormatado}/json/`
      );

      if (response.data && !response.data.erro) {
        setValue("endereco", response.data.logradouro);
        setValue("bairro", response.data.bairro);
        setValue("cidade", response.data.localidade);

        // Verifica se o UF retornado é válido
        const estadoValido = estadosBrasileiros.some(
          (estado) => estado.sigla === response.data.uf
        );
        if (estadoValido) {
          setValue("uf", response.data.uf);
        } else {
          console.error("UF não é válida:", response.data.uf);
        }
      } else {
        console.error("CEP não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar CEP:", error);
    }
  };

  // UseEffect para chamar a função quando o CEP mudar
  useEffect(() => {
    if (cep && cep.length === 9) {
      // Verifica se o CEP tem o formato correto
      buscarEnderecoPorCEP(cep);
    }
  }, [cep]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              {...register("nomeCompleto", {
                required: "Nome Completo é obrigatório",
              })}
              label="Nome Completo"
              fullWidth
              error={!!errors.nomeCompleto}
              helperText={errors.nomeCompleto?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputMask
              mask="***.***.***-**"
              {...register("cpf", {
                required: "CPF é obrigatório",
                validate: {
                  validCPF: (value) => validarCPF(value) || "CPF inválido",
                },
              })}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="CPF"
                  fullWidth
                  error={!!errors.cpf}
                  helperText={errors.cpf?.message}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputMask
              mask="99/99/9999"
              {...register("dataNascimento", {
                required: "Data de Nascimento é obrigatória",
              })}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="Data de Nascimento"
                  fullWidth
                  error={!!errors.dataNascimento}
                  helperText={errors.dataNascimento?.message}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputMask
              mask="99.999.999-9"
              {...register("rg", {
                required: "Registro de Identidade é obrigatório",
              })}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="Nº Registro de Identidade (RG)"
                  fullWidth
                  error={!!errors.rg}
                  helperText={errors.rg?.message}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputMask
              mask="99/99/9999"
              {...register("dataEmissao", {
                required: "Data de Emissão é obrigatória",
              })}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="Data de Emissão"
                  fullWidth
                  error={!!errors.dataEmissao}
                  helperText={errors.dataEmissao?.message}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              select
              label="Estado"
              {...register("uf", { required: "UF é obrigatória" })}
              fullWidth
              error={!!errors.uf}
              helperText={errors.uf?.message}
            >
              {estadosBrasileiros.map((estado) => (
                <MenuItem key={estado.sigla} value={estado.sigla}>
                  {estado.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              {...register("orgaoEmissor", {
                required: "Órgão Emissor é obrigatório",
              })}
              label="Órgão Emissor"
              fullWidth
              error={!!errors.orgaoEmissor}
              helperText={errors.orgaoEmissor?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register("nomeMae", {
                required: "Nome da Mãe é obrigatório",
              })}
              label="Nome da Mãe"
              fullWidth
              error={!!errors.nomeMae}
              helperText={errors.nomeMae?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register("nomePai")}
              label="Nome do Pai"
              fullWidth
              error={!!errors.nomePai}
              helperText={errors.nomePai?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register("email", {
                required: "E-mail é obrigatório",
                pattern: { value: /^\S+@\S+$/i, message: "E-mail inválido" },
              })}
              label="E-mail"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputMask
              mask="(99) 99999-9999"
              {...register("telefone", { required: "Telefone é obrigatório" })}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="Telefone"
                  fullWidth
                  error={!!errors.telefone}
                  helperText={errors.telefone?.message}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputMask
              mask="99999-999"
              {...register("cep", { required: "CEP é obrigatório" })}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="CEP"
                  fullWidth
                  error={!!errors.cep}
                  helperText={errors.cep?.message}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register("endereco", { required: "Endereço é obrigatório" })}
              label="Endereço"
              fullWidth
              error={!!errors.endereco}
              helperText={errors.endereco?.message}
              InputLabelProps={{
                shrink: !!watch("endereco"), // Garante que a label suba quando o valor é preenchido
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              {...register("numero", { required: "Número é obrigatório" })}
              label="Número"
              fullWidth
              error={!!errors.numero}
              helperText={errors.numero?.message}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              {...register("bairro", { required: "Bairro é obrigatório" })}
              label="Bairro"
              fullWidth
              error={!!errors.bairro}
              helperText={errors.bairro?.message}
              InputLabelProps={{
                shrink: !!watch("bairro"), // Garante que a label suba quando o valor é preenchido
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              {...register("complemento")}
              label="Complemento"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              {...register("cidade", { required: "Cidade é obrigatória" })}
              label="Cidade"
              fullWidth
              error={!!errors.cidade}
              helperText={errors.cidade?.message}
              InputLabelProps={{
                shrink: !!watch("cidade"), // Garante que a label suba quando o valor é preenchido
              }}
            />
          </Grid>
        </Grid>

        <p>
          Caso detecte algum erro nos dados a seguir, volte ao campo
          correspondente para corrigir. Erro em algum desses campos implicará em{" "}
          <b>ATRASO</b> na entrega do seu passaporte. Seguindo o padrão
          internacional ICAO, os sobrenomes poderão ser abreviados na impressão
          do documento de viagem.
        </p>

        <FormControlLabel
          control={
            <Checkbox
              {...register("declaracao", {
                required:
                  "Você deve declarar que as informações estão corretas.",
              })}
              onChange={() => setCheckDeclaracao(!checkDeclaracao)}
            />
          }
          label="Declaro que as informações acima estão corretas e que estou ciente de que qualquer erro nos dados implicará em atraso na emissão do meu documento de viagem."
        />
        {errors.declaracao && (
          <Typography color="error">{errors.declaracao.message}</Typography>
        )}

        <center>
          <button type="submit" className="btn-bl" disabled={loading}>
            {loading ? "Carregando..." : "Continuar"}
          </button>
        </center>
      </form>
    </Container>
  );
};

export default CadastroForm;
